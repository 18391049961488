import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    default: String
  }

  reset() {
    if (this.hasDefaultValue) {
      this.element.value = this.defaultValue
    } else {
      this.element.selectedIndex = null
    }
  }
}
